import {
  Box,
  Button,
  Fab,
  Grid,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { IconX } from "@tabler/icons";
import { Link } from "react-router-dom";
import React from "react";

const PaymentInternalError = ({statusCode}) => {
  return (
    <>
      <Grid item xs={12} sm={8} order={{ xs: 2, sm: 1 }}>
        <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
          <Stack display='flex'
                 spacing={2}
                 direction='column'
                 justifyContent='stretch'
                 alignItems='center'>
            <Fab color='error' size='medium'>
              <IconX width={24} />
            </Fab>
            <Typography variant='h5' align='center'>Error</Typography>
            {<Typography variant='h7' align='center'>Could not </Typography>}
            <Box height={15} />
            <Button component={Link} to='/dashboard' variant='contained'
                    color='info'>Return Home</Button>
          </Stack>
        </Paper>
      </Grid>
    </>
  );
};

export default PaymentInternalError;
